var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "main" }, [
      _c("div", { staticClass: "text-center mt-5" }, [
        _c("h1", [_c("b", [_vm._v("500 Server Error")])]),
        _c("h4", [_vm._v("サーバーエラーが発生しました")]),
        _c("p", [
          _vm._v("恐れ入りますが、しばらく経ってから再度お試しください")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }